/* eslint-disable react/prop-types */
// import { PropTypes } from "prop-types";

import React, { Suspense, useRef, useState, useEffect } from "react";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks
} from "body-scroll-lock";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Canvas } from "@react-three/fiber";
import { useBreakpoint } from "gatsby-plugin-breakpoints";
import {
  Button,
  ChocPop,
  ChocPopsBox,
  Go,
  Tub,
  Grid,
  SwiperCarousel,
  Image,
  StockistSticker
} from "~components";
import { MEDIA_QUERIES } from "~utils/helpers";

import { ReactComponent as Arrow } from "~assets/icons/arrow-light.svg";
import { ReactComponent as StickerClose } from "~assets/stickers/close.svg";

import { ReactComponent as ChocPopStickerHealth } from "~assets/stickers/chocpops-health.svg";
import { ReactComponent as ChocPopStickerTaste } from "~assets/stickers/chocpops-taste.svg";
import { ReactComponent as ChocPopStickerStockists } from "~assets/stickers/chocpops-stockists-native.svg";
import { ReactComponent as ChocPopStickerStockistsHover } from "~assets/stickers/chocpops-stockists-hover.svg";

import { ReactComponent as IceCreamStickerHealth } from "~assets/stickers/icecream-health.svg";
import { ReactComponent as IceCreamStickerTaste } from "~assets/stickers/icecream-taste.svg";
import { ReactComponent as IceCreamStickerStockists } from "~assets/stickers/icecream-stockists-native.svg";
import { ReactComponent as IceCreamStickerStockistsHover } from "~assets/stickers/icecream-stockists-hover.svg";

import { ReactComponent as DairyFreeStickerHealth } from "~assets/stickers/dairyfree-health.svg";
import { ReactComponent as DairyFreeStickerTaste } from "~assets/stickers/dairyfree-taste.svg";
import { ReactComponent as DairyFreeStickerStockists } from "~assets/stickers/dairyfree-stockists-native.svg";
import { ReactComponent as DairyFreeStickerStockistsHover } from "~assets/stickers/dairyfree-stockists-hover.svg";

// lil nadas
import { ReactComponent as LilNadasHealthy } from "~assets/stickers/lilnadas-healthy-sticker.svg";
import { ReactComponent as LilNadasStory } from "~assets/stickers/lilnadas-story-sticker.svg";

// lil nadas animation
import lilNadasTitle from "~assets/animations/lil-nadas-title.gif";

// dairy free tub image
import dairyFreeTub from "~assets/images/dairy-free-collection-caramel-macadamia.png";

//

import iceCreamHealth1Desktop from "~assets/images/cards/icecream/icecream-health-1-desktop.png";
import iceCreamHealth1mobile from "~assets/images/cards/icecream/icecream-health-1-mobile.png";
import iceCreamHealth2Desktop from "~assets/images/cards/icecream/icecream-health-2-desktop.png";
import iceCreamHealth2mobile from "~assets/images/cards/icecream/icecream-health-2-mobile.png";
import iceCreamHealth3Desktop from "~assets/images/cards/icecream/icecream-health-3-desktop.png";
import iceCreamHealth3mobile from "~assets/images/cards/icecream/icecream-health-3-mobile.png";

import iceCreamTaste1Desktop from "~assets/images/cards/icecream/icecream-taste-1-desktop.png";
import iceCreamTaste1Mobile from "~assets/images/cards/icecream/icecream-taste-1-mobile.png";
import iceCreamTaste2Desktop from "~assets/images/cards/icecream/icecream-taste-2-desktop.png";
import iceCreamTaste2Mobile from "~assets/images/cards/icecream/icecream-taste-2-mobile.png";
import iceCreamTaste3Desktop from "~assets/images/cards/icecream/icecream-taste-3-desktop.png";
import iceCreamTaste3Mobile from "~assets/images/cards/icecream/icecream-taste-3-mobile.png";

import ChocPopHealth1Desktop from "~assets/images/cards/chocpops/chocpops-health-1-desktop.png";
import ChocPopHealth1mobile from "~assets/images/cards/chocpops/chocpops-health-1-mobile.png";
import ChocPopHealth2Desktop from "~assets/images/cards/chocpops/chocpops-health-2-desktop.png";
import ChocPopHealth2mobile from "~assets/images/cards/chocpops/chocpops-health-2-mobile.png";
import ChocPopHealth3Desktop from "~assets/images/cards/chocpops/chocpops-health-3-desktop.png";
import ChocPopHealth3mobile from "~assets/images/cards/chocpops/chocpops-health-3-mobile.png";

import ChocPopTaste1Desktop from "~assets/images/cards/chocpops/chocpops-taste-1-desktop.png";
import ChocPopTaste1Mobile from "~assets/images/cards/chocpops/chocpops-taste-1-mobile.png";
import ChocPopTaste2Desktop from "~assets/images/cards/chocpops/chocpops-taste-2-desktop.png";
import ChocPopTaste2Mobile from "~assets/images/cards/chocpops/chocpops-taste-2-mobile.png";
import ChocPopTaste3Desktop from "~assets/images/cards/chocpops/chocpops-taste-3-desktop.png";
import ChocPopTaste3Mobile from "~assets/images/cards/chocpops/chocpops-taste-3-mobile.png";

import DairyFreeTaste1Desktop from "~assets/images/cards/dairyfree/dairy-free-taste-1-desktop.png";
import DairyFreeTaste1Mobile from "~assets/images/cards/dairyfree/dairy-free-taste-1-mobile.png";
import DairyFreeTaste2Desktop from "~assets/images/cards/dairyfree/dairy-free-taste-2-desktop.png";
import DairyFreeTaste2Mobile from "~assets/images/cards/dairyfree/dairy-free-taste-2-mobile.png";
import DairyFreeTaste3Desktop from "~assets/images/cards/dairyfree/dairy-free-taste-3-desktop.png";
import DairyFreeTaste3Mobile from "~assets/images/cards/dairyfree/dairy-free-taste-3-mobile.png";

import DairyFreeHealth1Desktop from "~assets/images/cards/dairyfree/dairy-free-health-1-desktop.png";
import DairyFreeHealth1Mobile from "~assets/images/cards/dairyfree/dairy-free-health-1-mobile.png";
import DairyFreeHealth2Desktop from "~assets/images/cards/dairyfree/dairy-free-health-2-desktop.png";
import DairyFreeHealth2Mobile from "~assets/images/cards/dairyfree/dairy-free-health-2-mobile.png";
import DairyFreeHealth3Desktop from "~assets/images/cards/dairyfree/dairy-free-health-3-desktop.png";
import DairyFreeHealth3Mobile from "~assets/images/cards/dairyfree/dairy-free-health-3-mobile.png";

import lilNadasHealth1Desktop from "~assets/images/cards/lilNadas/health/desktop/lilnadas-cards-health-1.png";
import lilNadasHealth2Desktop from "~assets/images/cards/lilNadas/health/desktop/lilnadas-cards-health-2.png";
import lilNadasHealth3Desktop from "~assets/images/cards/lilNadas/health/desktop/lilnadas-cards-health-3.png";
import lilNadasHealth1Mobile from "~assets/images/cards/lilNadas/health/mobile/lilnadas-cards-health-1.png";
import lilNadasHealth2Mobile from "~assets/images/cards/lilNadas/health/mobile/lilnadas-cards-health-2.png";
import lilNadasHealth3Mobile from "~assets/images/cards/lilNadas/health/mobile/lilnadas-cards-health-3.png";
import lilNadasStory1Desktop from "~assets/images/cards/lilNadas/story/desktop/lilnadas-cards-story-1.png";
import lilNadasStory2Desktop from "~assets/images/cards/lilNadas/story/desktop/lilnadas-cards-story-2.png";
import lilNadasStory3Desktop from "~assets/images/cards/lilNadas/story/desktop/lilnadas-cards-story-3.png";
import lilNadasStory1Mobile from "~assets/images/cards/lilNadas/story/mobile/lilnadas-cards-story-1.png";
import lilNadasStory2Mobile from "~assets/images/cards/lilNadas/story/mobile/lilnadas-cards-story-2.png";
import lilNadasStory3Mobile from "~assets/images/cards/lilNadas/story/mobile/lilnadas-cards-story-3.png";

// ice cream tub image
import peanutButterTub from "~assets/images/tub-collection-peanut-butter-front.png";

const Wrapper = styled.div`
  width: 100%;
  position: relative;
`;

const ClipSection = styled.section`
  width: 100%;
  height: 100vh;
  position: relative;
  z-index: 10;
  clip: rect(auto, auto, auto, auto);
  clip-path: inset(0 0 0 0);
  pointer-events: none;
`;

const FixedContainer = styled.div`
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;

  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const NadaAnimation = styled.div`
  transform: scaleX(-1);
  width: 36%;
  max-width: 180px;
  position: absolute;
  bottom: 1rem;
  right: 1rem;
  z-index: 20;
  display: block;

  ${MEDIA_QUERIES.tablet} {
    right: -10%;
    bottom: -5rem;
    width: 24%;
  }
`;

// Box wrapper
const BoxWrapper = styled.div`
  grid-column: span 12 / span 12;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  // new styles
  top: 50%;
  left: 50%;
  transform: translate3d(-50%, -50%, 0);

  ${MEDIA_QUERIES.tablet} {
    height: 100vw;
    transform: translate3d(-50%, -50%, 0);
  }

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 6 / span 6;
    height: 100%;
    max-height: none;
    position: relative;
  }
`;

// Tub
const TubContainer = styled.div`
  grid-column: -1 / 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 7rem;
  height: 100%;

  ${MEDIA_QUERIES.tablet} {
    padding: 3rem 0;
  }

  ${MEDIA_QUERIES.desktop} {
    grid-column: span 6 / span 6;
    padding: 0;
  }
`;

const TubFigure = styled.figure`
  transition: 0.15s var(--cubic-easing) transform;
  cursor: pointer;
  width: 90%;
  position: relative;

  ${MEDIA_QUERIES.tablet} {
    width: 55%;
  }

  ${MEDIA_QUERIES.desktop} {
    width: 40%;
  }
`;

const TubImg = styled.img`
  width: 100%;
  user-select: none;

  ${MEDIA_QUERIES.desktop} {
    //
  }
`;

const CollectionBannerCard = ({ className, image }) => (
  <article
    css={css`
      grid-column: span 4;
      width: 100%;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      transform: translate3d(0, 0, 0);
    `}
    className={className}
  >
    <Image
      image={image}
      css={css`
        width: 100%;
        position: relative;
        display: block;
        z-index: 10;
      `}
    />
  </article>
);

//

const CollectionBanner = ({
  data: {
    primaryColour,
    secondaryColour,
    tertiaryColour,
    heading,
    subText,
    button,
    objectType
  },
  path
}) => {
  // ---------------------------------------------------------------------------
  // context / ref / state

  const { isDesktop } = useBreakpoint();

  const tubRef = useRef();

  const [stickerStockistsHover, setStickerStockistsHover] = useState(false);
  const [boxInteraction, setBoxInteraction] = useState(false);
  const [cardOverlay, setCardOverlay] = useState(null);
  const [collectionPageType, setCollectionPageType] = useState(``);
  const [cardOverlayImages, setCardOverlayImages] = useState();
  const [stickers, setStickers] = useState({
    health: null,
    taste: null,
    stockists: null,
    stockistsHover: null
  });
  // mouse pos for tub banner
  const [pos, setPos] = useState({
    x: 0,
    y: 0
  });

  // ---------------------------------------------------------------------------
  // variables

  const isTubAsset = objectType === `tub` || objectType === `dairyFreeTub`;

  let scrollTargetElement;
  // let HealthSticker;

  if (typeof document !== `undefined`) {
    scrollTargetElement = document.getElementById(`card-overlay`);
  }

  const chocPopFlavors = [
    {
      id: `mint`,
      backgroundPrimary: `var(--color-mint-dark)`,
      backgroundSecondary: `var(--color-mint)`
    },
    {
      id: `almond`,
      backgroundPrimary: `var(--color-almond-brown)`,
      backgroundSecondary: `var(--color-milk-choc-pink)`
    },
    {
      id: `caramel`,
      backgroundPrimary: `var(--color-salted-caramel)`,
      backgroundSecondary: `var(--color-milk-choc-pink)`
    },
    {
      id: `hazelnut`,
      backgroundPrimary: `var(--color-choc-hazelnut-cobalt)`,
      backgroundSecondary: `var(--color-cookies-light)`
    }
  ];

  // change this to use the above
  const healthCards = [
    {
      id: 1,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopHealth1Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeHealth1Desktop
        : iceCreamHealth1Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopHealth1mobile
        : path.includes(`dairy-free`)
        ? DairyFreeHealth1Mobile
        : iceCreamHealth1mobile,
      backgroundColor: `black`
    },
    {
      id: 2,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopHealth2Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeHealth2Desktop
        : iceCreamHealth2Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopHealth2mobile
        : path.includes(`dairy-free`)
        ? DairyFreeHealth2Mobile
        : iceCreamHealth2mobile,
      backgroundColor: `white`
    },
    {
      id: 3,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopHealth3Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeHealth3Desktop
        : iceCreamHealth3Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopHealth3mobile
        : path.includes(`dairy-free`)
        ? DairyFreeHealth3Mobile
        : iceCreamHealth3mobile,
      backgroundColor: `white`
    }
  ];

  const tasteCards = [
    {
      id: 1,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopTaste1Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeTaste1Desktop
        : iceCreamTaste1Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopTaste1Mobile
        : path.includes(`dairy-free`)
        ? DairyFreeTaste1Mobile
        : iceCreamTaste1Mobile,
      backgroundColor: `black`
    },
    {
      id: 2,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopTaste2Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeTaste2Desktop
        : iceCreamTaste2Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopTaste2Mobile
        : path.includes(`dairy-free`)
        ? DairyFreeTaste2Mobile
        : iceCreamTaste2Mobile,
      backgroundColor: `white`
    },
    {
      id: 3,
      desktopImage: path.includes(`choc-pop`)
        ? ChocPopTaste3Desktop
        : path.includes(`dairy-free`)
        ? DairyFreeTaste3Desktop
        : iceCreamTaste3Desktop,
      mobileImage: path.includes(`choc-pop`)
        ? ChocPopTaste3Mobile
        : path.includes(`dairy-free`)
        ? DairyFreeTaste3Mobile
        : iceCreamTaste3Mobile,
      backgroundColor: `white`
    }
  ];

  const lilNadasHealthCards = [
    {
      id: 1,
      desktopImage: lilNadasHealth1Desktop,
      mobileImage: lilNadasHealth1Mobile,
      backgroundColor: `black`
    },
    {
      id: 2,
      desktopImage: lilNadasHealth2Desktop,
      mobileImage: lilNadasHealth2Mobile,
      backgroundColor: `black`
    },
    {
      id: 3,
      desktopImage: lilNadasHealth3Desktop,
      mobileImage: lilNadasHealth3Mobile,
      backgroundColor: `black`
    }
  ];

  const lilNadasStoryCards = [
    {
      id: 1,
      desktopImage: lilNadasStory1Desktop,
      mobileImage: lilNadasStory1Mobile,
      backgroundColor: `white`
    },
    {
      id: 2,
      desktopImage: lilNadasStory2Desktop,
      mobileImage: lilNadasStory2Mobile,
      backgroundColor: `white`
    },
    {
      id: 3,
      desktopImage: lilNadasStory3Desktop,
      mobileImage: lilNadasStory3Mobile,
      backgroundColor: `white`
    }
  ];

  // ---------------------------------------------------------------------------
  // methods
  const relativeCursorHandler = (e) => {
    if (!e || !tubRef?.current || !isDesktop) return;

    const { left, top } = tubRef.current.parentNode.getBoundingClientRect();

    setPos({
      x: e.clientX - left,
      y: e.clientY - top
    });
  };

  // ---------------------------------------------------------------------------
  // lifecycle

  useEffect(() => {
    const componentMappings = {
      "choc-pop": {
        collectionPageType: `choc-pop`,
        HealthStickerComponent: ChocPopStickerHealth,
        TasteStickerComponent: ChocPopStickerTaste,
        StockistsNativeStickerComponent: ChocPopStickerStockists,
        StockistsHoverStickerComponent: ChocPopStickerStockistsHover
      },
      "ice-cream": {
        collectionPageType: `ice-cream`,
        HealthStickerComponent: IceCreamStickerHealth,
        TasteStickerComponent: IceCreamStickerTaste,
        StockistsNativeStickerComponent: IceCreamStickerStockists,
        StockistsHoverStickerComponent: IceCreamStickerStockistsHover
      },
      "lil-nadas": {
        collectionPageType: `lil-nadas`
      },
      "dairy-free": {
        collectionPageType: `dairy-free`,
        HealthStickerComponent: DairyFreeStickerHealth,
        TasteStickerComponent: DairyFreeStickerTaste,
        StockistsNativeStickerComponent: DairyFreeStickerStockists,
        StockistsHoverStickerComponent: DairyFreeStickerStockistsHover
      }
    };

    let selectedComponent = {};

    Object.keys(componentMappings).forEach((key) => {
      if (path.includes(key)) {
        selectedComponent = componentMappings[key];
      }
    });

    if (!selectedComponent.collectionPageType) {
      console.warn(`Unknown path: ${path}`);
      return;
    }

    setCollectionPageType(selectedComponent.collectionPageType);

    setStickers({
      health: selectedComponent.HealthStickerComponent || null,
      taste: selectedComponent.TasteStickerComponent || null,
      stockists: selectedComponent.StockistsNativeStickerComponent || null,
      stockistsHover: selectedComponent.StockistsHoverStickerComponent || null
    });
  }, [path]);

  useEffect(() => {
    if (typeof window === `undefined` || !scrollTargetElement) {
      return;
    }

    if (cardOverlay) {
      disableBodyScroll(scrollTargetElement);
    } else {
      enableBodyScroll(scrollTargetElement);
    }

    if (cardOverlay === `taste`) {
      setCardOverlayImages(tasteCards);
    } else if (cardOverlay === `health`) {
      setCardOverlayImages(healthCards);
    } else if (cardOverlay === `lilNadasStory`) {
      setCardOverlayImages(lilNadasStoryCards);
    } else if (cardOverlay === `lilNadasHealth`) {
      setCardOverlayImages(lilNadasHealthCards);
    }
  }, [cardOverlay]);

  // ---------------------------------------------------------------------------
  // render

  const HealthSticker = stickers.health;
  const TasteSticker = stickers.taste;
  const StockistsSticker = stickers.stockists;
  const StockistsHoverSticker = stickers.stockistsHover;

  return (
    <Wrapper
      ref={isTubAsset ? tubRef : null}
      onMouseMove={(e) => (isTubAsset ? relativeCursorHandler(e) : null)}
    >
      {/* Card overlay */}
      <div
        id="card-overlay"
        css={css`
          transition: 0.5s ease opacity, 0.5s ease transform;

          opacity: ${cardOverlay ? `1` : `0`};
          pointer-events: ${cardOverlay ? `auto` : `none`};
          transform: scale(${cardOverlay ? `1` : `1.05`});

          width: 100%;
          height: 100%;
          position: fixed;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 20;
          display: flex;
          align-items: center;
        `}
      >
        {cardOverlay && (
          <>
            {isDesktop ? (
              <Grid _css={css``}>
                {cardOverlayImages?.map((card, cardIndex) => (
                  <CollectionBannerCard
                    key={card.id}
                    css={css`
                      animation: var(--animation-appear-up);
                      animation-delay: ${cardIndex * 200}ms;
                      transform: translate3d(0, 1rem, 0);
                      opacity: 0;

                      background-color: var(--color-${card.backgroundColor});
                    `}
                    image={card.desktopImage}
                  />
                ))}
              </Grid>
            ) : (
              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  position: relative;
                  display: flex;
                  align-items: center;
                `}
              >
                <SwiperCarousel
                  loop={false}
                  css={css`
                    padding: 0px 1rem !important;
                  `}
                  options={{ slidesPerView: 1.3, spaceBetween: 16 }}
                  slides={
                    cardOverlayImages?.map((card) => (
                      <CollectionBannerCard
                        key={card.id}
                        css={css`
                          background-color: var(
                            --color-${card.backgroundColor}
                          );
                          background-image: url(${card.mobileImage});
                          background-size: contain;
                          background-repeat: no-repeat;
                        `}
                        image={card.mobileImage}
                      />
                    )) || []
                  }
                />
              </div>
            )}
          </>
        )}

        <button
          type="button"
          css={css`
            position: absolute;
            top: 3.5rem;
            right: 3.33vw;
            z-index: 1;

            ${MEDIA_QUERIES.hoverable} {
              :hover {
                cursor: pointer;
              }
            }

            ${MEDIA_QUERIES.desktop} {
              top: 15%;
            }
          `}
          onClick={() => setCardOverlay(null)}
        >
          <StickerClose
            css={css`
              transform: rotate(15deg);
            `}
          />
        </button>
      </div>

      {/* // relative content // */}

      <ClipSection>
        <FixedContainer>
          <article
            css={css`
              width: 100%;
              height: 100%;
              position: relative;
              z-index: 10;
              display: flex;
              align-items: center;
              justify-content: center;
              padding: 2.25rem 0.5rem;
              background-color: ${primaryColour?.hex || `var(--color-white)`};

              ${MEDIA_QUERIES.desktop} {
                padding: 0 0 4rem;
              }
            `}
          >
            <div
              css={css`
                ${MEDIA_QUERIES.tablet} {
                  position: relative;
                }
              `}
            >
              <h1
                className="d1"
                css={css`
                  animation: 3s ease-in-out blur-in forwards;
                  color: ${tertiaryColour?.hex || `var(--color-black)`};
                  text-align: center;
                  text-transform: uppercase;
                  white-space: pre-line;
                `}
              >
                {/* // todo : remove in CMS once deployed // */}
                {heading.replace(`*`, ``)}
              </h1>
              {objectType === `nadas` && (
                <NadaAnimation>
                  <img
                    css={css`
                      width: 100%;
                      position: relative;
                      display: block;
                    `}
                    src="/images/lil-nada.gif"
                    alt="Happy Lil Nada"
                  />
                </NadaAnimation>
              )}
            </div>

            <div
              css={css`
                animation: var(--animation-appear-up);
                animation-duration: 3s;

                width: 100%;
                position: absolute;
                bottom: 0;
                right: 0;
                left: 0;
                z-index: 10;
                pointer-events: none;
                display: flex;
                align-items: center;
                justify-content: center;
                padding-bottom: 3rem;

                ${MEDIA_QUERIES.desktop} {
                  padding-bottom: 1rem;
                }
              `}
            >
              <Arrow
                css={css`
                  animation: float var(--animation-float);
                  width: 1rem;
                `}
                fill={tertiaryColour?.hex}
              />
            </div>
          </article>
        </FixedContainer>
      </ClipSection>

      {(collectionPageType === `lil-nadas` && (
        <ClipSection
          css={css`
            ${MEDIA_QUERIES.desktop} {
              height: 150vh;
            }
          `}
        >
          <FixedContainer
            css={css`
              background: ${secondaryColour?.hex};
              transition: 0.5s ease filter;
              filter: blur(${cardOverlay ? `14px` : `0px`});
            `}
          >
            <figure
              css={css`
                width: 100%;
                height: 100%;
                position: relative;
                display: flex;
                align-items: flex-end;
                justify-content: center;
                padding: 1.5rem 0;
                ${MEDIA_QUERIES.tablet} {
                  justify-content: flex-start;
                  padding: 2rem;
                  align-items: center;
                }
              `}
            >
              <img
                css={css`
                  width: 90%;
                `}
                src={lilNadasTitle}
                alt="Lil Nada Jumping Around"
              />
            </figure>

            {/* stickers */}
            <div
              css={css`
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                right: 0;
                bottom: 0;
                left: 0;
              `}
            >
              {/* Stockists */}
              <div
                css={css`
                  width: 40%;
                  height: 40%;
                  position: absolute;
                  right: 1rem;
                  top: 30%;
                  max-width: 180px;
                  z-index: 10;
                  ${MEDIA_QUERIES.tablet} {
                    top: 6rem;
                    right: 2rem;
                    width: 180px;
                    height: 180px;
                  }
                `}
              >
                <StockistSticker
                  css={css`
                    transform: rotate(10deg);
                  `}
                  color="light-brown"
                />
              </div>
              <div
                css={css`
                  position: relative;
                  margin-top: 4rem;
                  margin-left: 1rem;
                  width: 56vw;
                  height: 56vw;
                  pointer-events: auto;
                  ${MEDIA_QUERIES.tablet} {
                    position: absolute;
                    bottom: 2rem;
                    right: 2rem;
                  }
                `}
              >
                {/* Is it even healthy? */}
                <div
                  css={css`
                    width: 80%;
                    position: absolute;
                    top: 0;
                    right: 0;
                    z-index: 10;
                    ${MEDIA_QUERIES.tablet} {
                      width: 14rem;
                      top: auto;
                      right: auto;
                      bottom: 7rem;
                      right: 0rem;
                    }
                    ${MEDIA_QUERIES.desktop} {
                      width: 18rem;
                      top: auto;
                      right: auto;
                      bottom: 9rem;
                      right: 0rem;
                    }
                  `}
                >
                  <LilNadasHealthy
                    onClick={() => setCardOverlay(`lilNadasHealth`)}
                    css={css`
                      transform: rotate(12deg);
                      cursor: pointer;
                      transition: transform 0.3s var(--cubic-easing);
                      :hover {
                        transform: rotate(12deg) scale(1.1);
                      }
                    `}
                  />
                </div>

                {/* Tell me a lil story */}
                <div
                  css={css`
                    width: 80%;
                    position: absolute;
                    top: 40%;
                    left: 0;
                    z-index: 10;
                    ${MEDIA_QUERIES.tablet} {
                      width: 14rem;
                      top: auto;
                      left: auto;
                      bottom: 0rem;
                      right: 5rem;
                    }
                    ${MEDIA_QUERIES.desktop} {
                      width: 18rem;
                      top: auto;
                      right: auto;
                      bottom: 0rem;
                      right: 5rem;
                    }
                  `}
                >
                  <LilNadasStory
                    onClick={() => setCardOverlay(`lilNadasStory`)}
                    css={css`
                      position: relative;
                      transition: transform 0.3s var(--cubic-easing);
                      transform: rotate(-8deg);
                      cursor: pointer;
                      z-index: 10;
                      :hover {
                        transform: rotate(-8deg) scale(1.1);
                      }
                    `}
                  />
                </div>
              </div>
            </div>
          </FixedContainer>
        </ClipSection>
      )) || (
        <ClipSection
          css={css`
            ${MEDIA_QUERIES.desktop} {
              height: 150vh;
            }
          `}
        >
          <FixedContainer
            css={css`
              background: ${secondaryColour?.hex};
            `}
          >
            <div
              css={css`
                width: 100vw;
                height: 100vh;
                position: relative;
                background: ${secondaryColour?.hex};
              `}
            >
              {/* Text */}
              <div
                css={css`
                  transition: 0.5s ease filter;

                  filter: blur(${cardOverlay ? `14px` : `0px`});

                  width: 100%;
                  height: auto;
                  position: absolute;
                  top: 6rem;
                  right: 0;
                  left: 0;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  color: var(--color-white);
                  z-index: 10;
                  text-align: center;

                  ${MEDIA_QUERIES.desktop} {
                    height: 100%;
                    top: 0;
                    bottom: 0;
                  }

                  ${cardOverlay &&
                  `
              pointer-events: none;
            `}
                `}
              >
                <h2
                  className={isDesktop ? `h1` : `h2`}
                  css={css`
                    width: 80%;

                    ${MEDIA_QUERIES.desktop} {
                      width: 100%;
                    }
                  `}
                >
                  WHAT MORE DO YOU NEED TO KNOW?
                </h2>
              </div>

              {/* Sticker Taste */}
              <div
                css={css`
                  transition: 0.5s ease filter;

                  filter: blur(${cardOverlay ? `14px` : `0px`});

                  width: 45vw;
                  height: 55vh;
                  position: absolute;
                  top: 5vw;
                  left: -5vw;
                  z-index: 10;
                  display: flex;
                  align-items: flex-end;
                  justify-content: flex-end;
                  overflow: visible;
                  pointer-events: auto;

                  // new
                  top: 0;
                  left: 1vw;
                  height: 79vw;

                  ${MEDIA_QUERIES.tablet} {
                    width: 20vw;
                    height: 35vh;
                    top: 0;
                    left: 0;
                  }

                  ${MEDIA_QUERIES.desktop} {
                    width: 20vw;
                    height: 35vh;
                  }

                  ${cardOverlay &&
                  `
              pointer-events: none;
            `}
                `}
              >
                {TasteSticker && (
                  <TasteSticker
                    css={css`
                      width: 90%;
                      transform: rotate(-20deg);
                      transition: transform 0.3s var(--cubic-easing);

                      :hover {
                        transform: rotate(-20deg) scale(1.1);
                        cursor: pointer;
                      }
                    `}
                    onClick={() => setCardOverlay(`taste`)}
                  />
                )}
              </div>

              {/* Sticker Health */}
              <div
                css={css`
                  transition: 0.5s ease filter;

                  filter: blur(${cardOverlay ? `14px` : `0px`});

                  width: 50vw;
                  height: 28vh;
                  position: absolute;
                  right: 0;
                  bottom: 0;
                  z-index: 10;
                  display: flex;
                  align-items: flex-start;
                  justify-content: flex-start;
                  pointer-events: auto;

                  ${MEDIA_QUERIES.tablet} {
                    width: 25vw;
                    height: 20vh;
                    bottom: 0;
                  }

                  ${MEDIA_QUERIES.desktop} {
                    width: 25vw;
                    height: 20vh;
                    bottom: 10vh;
                  }

                  ${cardOverlay &&
                  `
              pointer-events: none;
            `}
                `}
              >
                {HealthSticker && (
                  <HealthSticker
                    css={css`
                      width: 80%;
                      transform: rotate(15deg);
                      transition: transform 0.3s var(--cubic-easing);

                      :hover {
                        transform: rotate(15deg) scale(1.1);
                        cursor: pointer;
                      }
                    `}
                    onClick={() => setCardOverlay(`health`)}
                  />
                )}
              </div>

              {/* Sticker Stockists */}
              <Go to="/stockists">
                <div
                  css={css`
                    transition: 0.5s ease filter;

                    filter: blur(${cardOverlay ? `14px` : `0px`});

                    width: 53vw;
                    position: absolute;
                    top: 32vh;
                    right: -20vw;
                    z-index: 10;
                    display: flex;
                    align-items: flex-end;
                    justify-content: flex-start;
                    pointer-events: auto;

                    ${MEDIA_QUERIES.tablet} {
                      width: 17vw;
                      top: 15vw;
                      right: 0;
                    }

                    ${MEDIA_QUERIES.desktop} {
                      width: 17vw;
                      top: 5vw;
                    }

                    ${cardOverlay &&
                    `
                  pointer-events: none;
                `}

                    ${MEDIA_QUERIES?.hoverable} {
                      &:hover {
                        cursor: pointer;
                      }
                    }
                  `}
                  onMouseEnter={() => setStickerStockistsHover(true)}
                  onMouseLeave={() => setStickerStockistsHover(false)}
                >
                  {StockistsSticker && (
                    <>
                      <StockistsSticker
                        css={css`
                          transition: all 0.3s var(--cubic-easing);
                          transform: rotate(15deg)
                            scale(${stickerStockistsHover ? `1.1` : `1`});

                          width: 55%;
                          position: absolute;
                          top: 0;
                          right: 0;
                          bottom: 0;
                          left: 0;
                          opacity: ${stickerStockistsHover ? `0` : `1`};

                          ${MEDIA_QUERIES.tablet} {
                            width: 80%;
                          }
                        `}
                      />

                      {StockistsHoverSticker && (
                        <StockistsHoverSticker
                          css={css`
                            transition: all 0.3s var(--cubic-easing);
                            transform: rotate(15deg)
                              scale(${stickerStockistsHover ? `1.1` : `1`});

                            width: 80%;
                            position: absolute;
                            top: 0;
                            right: 0;
                            bottom: 0;
                            left: 0;
                            opacity: ${stickerStockistsHover ? `1` : `0`};
                          `}
                        />
                      )}
                    </>
                  )}
                </div>
              </Go>

              {/* 3D Tub */}
              {/* <Canvas
                colorManagement
                camera={{ position: [0, 0, 100], fov: 100, zoom: 10 }}
                style={{ filter: `blur(${cardOverlay ? `14px` : `0px`})` }}
              >
                <ambientLight intensity={0.6} />
                <directionalLight
                  castShadow
                  position={[20, 0, 10]}
                  intensity={0.2}
                />

                <Suspense fallback={null}>
                  {objectType === `box` && (
                    <ChocPop
                      scale={isDesktop ? [120, 120, 120] : [80, 80, 80]}
                      position={[0, isDesktop ? 1 : 0.5, 5]}
                      rotation={[0, 0, -0.3]}
                    />
                  )}

                  {isTubAsset && (
                    <Tub
                      position={[0, isDesktop ? 0 : 1, 5]}
                      rotation={[0.33, 0, 0.25]}
                      scale={isDesktop ? [110, 110, 110] : [70, 70, 70]}
                    />
                  )}
                </Suspense>
              </Canvas> */}
              {objectType === `box` && (
                // <Canvas
                //   colorManagement
                //   camera={{ position: [0, 0, 100], fov: 100, zoom: 10 }}
                //   style={{ filter: `blur(${cardOverlay ? `14px` : `0px`})` }}
                // >
                //   <ambientLight intensity={0.6} />
                //   <directionalLight
                //     castShadow
                //     position={[20, 0, 10]}
                //     intensity={0.2}
                //   />

                //   <Suspense fallback={null}>
                //     <ChocPop
                //       scale={isDesktop ? [120, 120, 120] : [80, 80, 80]}
                //       position={[0, isDesktop ? 1 : 0.5, 5]}
                //       rotation={[0, 0, -0.3]}
                //     />
                //   </Suspense>
                // </Canvas>
                <BoxWrapper>
                  <div
                    css={css`
                      animation: float var(--animation-float);

                      width: 46%;
                      height: 120vw;
                      position: relative;

                      padding-top: 6rem;

                      ${MEDIA_QUERIES.tablet} {
                        width: 50%;
                        margin: 0 auto;
                        padding-top: 0;
                      }

                      ${MEDIA_QUERIES.desktop} {
                        width: 100%;
                        height: 100%;
                      }
                    `}
                  >
                    <div
                      css={css`
                        transition: transform 0.3s var(--cubic-easing);

                        width: 100%;
                        height: 100%;
                        position: relative;
                        display: flex;
                        align-items: center;
                        justify-content: center;

                        ${MEDIA_QUERIES.hoverable} {
                          &:hover {
                            transform: scale(1.05);
                          }
                        }
                      `}
                    >
                      <div
                        css={css`
                          transition: transform 0.15s var(--cubic-easing);

                          transform: scale(${boxInteraction ? `0.9` : `1`});

                          width: 100%;
                          position: relative;
                          z-index: 5;
                          user-select: none;
                          pointer-events: auto;
                          cursor: pointer;

                          ${MEDIA_QUERIES.desktop} {
                            width: 400px;
                          }
                        `}
                        role="presentation"
                        onMouseDown={() => setBoxInteraction(true)}
                        onMouseUp={() => {
                          setBoxInteraction(false);
                        }}
                      >
                        {/* <ChocPopsBox flavour={flavours?.[flavour]} visible={inView} /> */}
                        <ChocPopsBox
                          flavour={chocPopFlavors[3]}
                          loadIn="left"
                          visible
                        />
                      </div>
                    </div>
                  </div>
                </BoxWrapper>
              )}

              {isTubAsset && (
                <TubContainer
                  css={css`
                    filter: blur(${cardOverlay ? `14px` : `0px`});
                  `}
                >
                  <TubFigure
                    css={css`
                      ${MEDIA_QUERIES.desktop} {
                        transform: translate3d(
                          ${parseInt(pos.x / 30)}px,
                          ${parseInt(pos.y / 30) - 40}px,
                          0
                        );
                      }
                    `}
                  >
                    <TubImg
                      src={
                        objectType === `tub` ? peanutButterTub : dairyFreeTub
                      }
                      alt="DEN"
                    />
                  </TubFigure>
                </TubContainer>
              )}

              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  position: fixed;
                  top: 0;
                  right: 0;
                  bottom: 0;
                  left: 0;
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                  justify-content: flex-end;
                  padding: 0;
                  z-index: 10;
                  opacity: ${cardOverlay ? `0` : `1`};

                  ${MEDIA_QUERIES.desktop} {
                    padding: 2rem 0 1rem;
                  }
                `}
              >
                <div
                  css={css`
                    animation: float var(--animation-float);

                    padding-bottom: 3rem;

                    ${MEDIA_QUERIES.desktop} {
                      padding-bottom: 0;
                    }
                  `}
                >
                  <Arrow
                    css={css`
                      width: 1rem;
                    `}
                    fill="var(--color-white)"
                  />
                </div>
              </div>
            </div>
          </FixedContainer>
        </ClipSection>
      )}
    </Wrapper>
  );
};

export default CollectionBanner;
